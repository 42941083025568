.business-vision-container {
    background-color: #121212; /* Dark background */
    color: #ffffff; /* White text */
    text-align: center;
    padding: 60px 60px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  
    h1 {
      font-size: 5.1rem;
      font-weight: bold;
      margin-bottom: 50px;
  
      .outline {
        color: transparent;
        -webkit-text-stroke: 1px #ffffff; /* White outline */
        text-transform: uppercase;
      }
    }
  
    .subtitle {
      font-size: 1.6rem;
      font-weight: 400;
      margin-bottom: 50px;
      margin-left: auto;
      margin-right: auto;
      line-height: 1.2;
    }
  
    .description {
      font-size: 1.6rem;
      font-weight: 400;
      margin-bottom: 30px;
      // max-width: 700px;
      margin-left: auto;
      margin-right: auto;
      line-height: 1.6;
      text-align: left;
    }
  
    .features-list {
      list-style: none;
      padding: 0;
      margin-top: 20px;
      // max-width: 600px;
      margin-left: auto;
      margin-right: auto;
      text-align: left;
  
      li {
        font-size: 1.6rem;
        margin-bottom: 10px;
        position: relative;
        padding-left: 20px;
  
        &:before {
          content: "•";
          color: #ffffff;
          position: absolute;
          left: 0;
        }
      }
    }
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .business-vision-container {
      h1 {
        font-size: 2.5rem;
      }
  
      .subtitle,
      .description {
        font-size: 1rem;
      }
  
      .features-list li {
        font-size: 0.9rem;
      }
    }
  }
  