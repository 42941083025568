.innovating-section {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  background-color: #121212;
  color: #ffffff;
  text-align: center;
  padding: 60px 58px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  background-image: url("../../assets/images/whyVirecubebg.png");

  h1 {
    font-size: 2.5rem;
    font-weight: 600;
    margin-bottom: 40px;
    text-align: left;
  }

  .content {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
    gap: 20px;
    align-items: stretch;

    .placeholder-box {
      flex-grow: 1;
      flex: 1 1;
      max-width: 400px;
      width: 100%;
      // border: 1px solid #ffffff;
      border-radius: 20px;
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
      position: relative;
      background-image: url("../../assets/images/innovating_virecube.png");
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      height: 100dvh;
      // &::before {
      //   content: "";
      //   position: absolute;
      //   top: 10px;
      //   left: 10px;
      //   right: 10px;
      //   bottom: 10px;
      //   background-size: cover;
      //   background-position: center;
      //   border-radius: 15px;
      // }
    }

    .features {
      flex: 2;
      display: flex;
      flex-direction: column;
      gap: 20px;

      .feature-card {
        background: transparent;
        border-radius: 20px;
        padding: 20px;
        color: #ffffff;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
        position: relative;
        transition: transform 0.2s ease, box-shadow 0.2s ease;
        border: 1px solid white;
        text-align: left;
        backdrop-filter: blur(10px); /* Add this line for the blur effect */
        -webkit-backdrop-filter: blur(10px); /* For Safari support */

        &:hover {
          transform: translateY(-5px);
          box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.5);
        }

        .feature-title {
          font-size: 1.6rem;
          letter-spacing: 2px;
          font-weight: 700;
          margin-top: 2px;
          margin-bottom: 1px;
        }

        .feature-description {
          font-size: 1.4rem;
          margin-bottom: 15px;
          line-height: 1.1;
          color: #ffffff;
          font-weight: 500;
        }

        &::before,
        &::after {
          content: "";
          position: absolute;
          background-image: url("../../assets/images/bolt.png");
          background-size: cover;
          width: 20px; /* Adjust as needed */
          height: 20px; /* Adjust as needed */
        }

        &::before {
          top: 10px;
          left: 10px;
        }

        &::after {
          top: 10px;
          right: 10px;
        }

        .bottom-left-bolt {
          position: absolute;
          bottom: 10px;
          left: 10px;
          background-image: url("../../assets/images/bolt.png");
          background-size: cover;
          width: 20px; /* Adjust as needed */
          height: 20px; /* Adjust as needed */
        }

        .bottom-right-bolt {
          position: absolute;
          bottom: 10px;
          right: 10px;
          background-image: url("../../assets/images/bolt.png");
          background-size: cover;
          width: 20px; /* Adjust as needed */
          height: 20px; /* Adjust as needed */
        }
      }
    }
  }
  /* Responsive Design for Tablets */
  @media (max-width: 1200px) {
    padding: 50px 40px;

    h1 {
      font-size: 2rem;
      text-align: center;
    }

    .content {
      .features {
        .feature-card {
          padding: 15px;
          .feature-title {
            font-size: 1.8rem;
          }
          .feature-description {
            font-size: 1.4rem;
          }
        }
      }
    }
  }

  /* Responsive Design for Medium Screens */
  @media (max-width: 768px) {
    padding: 40px 20px;

    h1 {
      font-size: 1.8rem;
    }

    .content {
      flex-direction: column;

      .placeholder-box {
        width: 100%;
        max-width: 300px;
        height: 300px;
      }

      .features {
        .feature-card {
          width: 100%;
          max-width: 400px;

          .feature-title {
            font-size: 1.6rem;
          }

          .feature-description {
            font-size: 1.2rem;
          }
        }
      }
    }
  }

  /* Responsive Design for Small Screens */
  @media (max-width: 480px) {
    padding: 20px 10px;

    h1 {
      font-size: 1.8rem;
    }

    .content {
      .placeholder-box {
        height: 250px;
      }

      .features {
        .feature-card {
          padding: 10px;
          max-width: 280px;
          .feature-title {
            font-size: 1.2rem;
            margin-top: 20px;
          }
          .feature-description {
            font-size: 0.8rem;
            margin-bottom: 25px;
          }
        }
      }
    }
  }
}
