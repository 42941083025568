.testimonials-container {
    background-color: #121212;
    color: #ffffff;
    text-align: center;
    padding: 60px 20px;
  
    .heading-testimonials {
      font-size: 3rem;
      margin-bottom: 40px;
      font-weight: 600;
      letter-spacing: 2px;
    }
  
    .testimonials-grid {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 20px;
      justify-items: center;
  
      @media (max-width: 768px) {
        grid-template-columns: repeat(1, 1fr);
      }
  
      @media (min-width: 769px) and (max-width: 1024px) {
        grid-template-columns: repeat(2, 1fr);
      }
    }
  
    .testimonial-card {
      font-family: sans-serif;
      background-color: #ffffff;
      color: #121212;
      border-radius: 10px;
      padding: 20px;
      max-width: 300px;
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
      text-align: left;
  
      .stars {
        font-size: 1.2rem;
        color: #ffc107;
        margin-bottom: 10px;
        letter-spacing: 2px;
      }
  
      .text {
        font-size: 1rem;
        margin-bottom: 20px;
        line-height: 1.3;
        font-weight: 400;
      }
  
      .author {
        display: flex;
        align-items: center;
  
        img {
          width: 50px;
          height: 50px;
          border-radius: 50%;
          margin-right: 15px;
        }
  
        .name {
          font-size: 1rem;
          font-weight: 700;
          color: #4F4F4F;
        }
  
        .position {
          font-size: 0.9rem;
          color: #777777;
        }
      }
    }
  }
  